@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    @apply bg-light font-primary text-base leading-base text-dark ;
    
  }



  h1 {
    @apply text-5xl
  }

  h2 {
    @apply text-4xl
  }

  h3 {
    @apply text-2xl
  }

h1, h2, h3 {
  @apply leading-headingHeight m-0  
}

img{
  @apply max-w-full max-h-72 shadow-2xl
}

} 

@screen md{
  h1 {
    @apply text-7xl 
  }

  h2 {
    @apply text-6xl
  }

  h3 {
    @apply text-3xl 
  }

  :root {
    @apply text-xl
  }
}

